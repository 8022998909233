.demo {
    font-family: 'Raleway', sans-serif;
    color: rgb(255, 255, 255);
    display: block;
    margin: 0 auto;
    padding: 15px 0;
    text-align: center;
}

.demo a {
    font-family: 'Raleway', sans-serif;
    color: #000;
}

.wave1 {
    position: absolute;
    width: 300%;
    height: 200px;
    bottom: 230px;
}

.grass {
    position: absolute;
    width: 300%;
    height: 200px;
    bottom: 240px;
    z-index: 0;
    background: url("../../images/grass.png") repeat-x;
}

.wave1:nth-of-type(1) {
    background: url("../../images/wave.png") repeat-x;
    transform: translate(0, 0px);
    animation: wave 25s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite;
    opacity: 1;
    z-index: 1;
}

.wave1:nth-of-type(2) {
    background: url("../../images/wave.png") repeat-x;
    transform: translate(0px, 80px);
    animation: wave 30s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite;
    opacity: 1;
    z-index: 2;
}

.wave1:nth-of-type(3) {
    background: url("../../images/wave.png") repeat-x;
    transform: translate(00px, 160px);
    animation: wave 25s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite;
    z-index: 3;
}

.wave1:nth-of-type(4) {
    background: url("../../images/wave.png") repeat-x;
    transform: translate(0px, 240px);
    animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite;
    opacity: 5;
    z-index: 4;
}

.wave1:nth-of-type(5) {
    background: url("../../images/wave.png") repeat-x;
    transform: translate(0px, 320px);
    animation: wave 25s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite;
    opacity: 1;
    z-index: 5;
}


@keyframes wave1 {
    100% {
        margin-left: 0;
    }

    0% {
        margin-left: -150%;
    }
}

.krathong {
    width: 200px;
    position: absolute;
    animation-delay: 5s;
}

.kanimate {
    width: 300px;
    position: absolute;
    z-index: 2;
    bottom: 75px;
}

@keyframes kra1 {
    100% {
        left: 100%;
    }

    0% {
        left: -300px;
    }

    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        transform: translate3d(0px, 0px, 0px) rotateZ(-1deg);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(0px, 10px, 10px) rotateZ(1deg);
    }
}